import * as React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import GustavPoola from "../images/gustav-poola.jpg";
import OskarPoola from "../images/oskar-poola.jpg";
import TarmoKouhkna from "../images/tarmo-kouhkna.jpg";
import KalleKuuspalu from "../images/kalle-kuuspalu.jpg";
import SergeiZamsharski from "../images/sergei-zamsharski.jpg";
import RihoKruuv from "../images/riho-kruuv.jpg";

import GustavPoolaCeo from "../images/gustav-poola-ceo.jpg";
import GustavPoolaSignature from "../images/gustav-poola-signature.png";

import LevercodeTallinn from "../images/levercode-tallinn.png";

const AboutPage = () => (
  <Layout>
    <Helmet title="About - Levercode" />
    {/* Content */}
    <div className="container max-w-screen-xl flex gap-8 mt-24">
      <h2 className="text-6xl text-blue-800 ">About Levercode</h2>
    </div>
    <div className="container max-w-screen-xl flex gap-8 pb-32">
      <div className="w-7/12">
        <p>Thank you for your interest in our company and Levercode’s applied approach to creating reliable data systems.</p>
        <p className="mt-8">
          Data may sound like dry and complicated domain but nowadays, none of us can ignore it. Out of all the data our main partners process, be them the Governments or large companies, they process data whose ownership is claimed by other persons, legal
          entities or individuals. Including yours.
        </p>
        <p className="mt-8">
          Therefore, our ultimate goal is to make sure that while reaping the benefits of digital technologies, your data are processed and handled efficiently without putting you at risk. Subsequently, we take it as our mission to provide Governments and
          companies with clear and reliable guidance, necessary technologies, and tools to creating reliable and sustainable data governance platforms.
        </p>
        <p className="mt-8">Data systems need not to be rocket science. Give us a call or message us! Our experts excel in listening you, explaining difficult domains with easy words and identify how we can offer the best value for data.</p>
        <p className="mt-16">Sincerely,</p>
        <p className="mt-8">
          <img src={GustavPoolaSignature} alt="" />
        </p>
        <p className="mt-8">Gustav Poola, CEO</p>
      </div>
      <div className="w-5/12">
        <img src={GustavPoolaCeo} alt="Gustav Poola" />
      </div>
    </div>

    <div className="bg-blue-800">
      <div className="container max-w-screen-2xl pt-32 pb-96 text-2xl text-white">
        <h2 className="text-6xl font-bold text-white text-center">Our roots and values</h2>
        <p className="mt-16 text-3xl leading-relaxed">
          Levercode was established in 2014 but its roots and raison d'être go much further into history and, through the founders, leaders and involved experts, are closely related to Estonia’s experience in conducting its Digital Transformation.
        </p>
        <div className="grid grid-cols-2 gap-16 mt-16 text-2xl">
          <div>
            <p>
              We see Levercode as an “innovative" technology company invoking “old” principles to create reliable, long-term sustainable “new” solutions. Invoking old principles means going back to the drawing board to discover the actual cause of
              associated deficiencies. We focus on complex system’s building blocks and their dependencies to each other rather than just build the “machine” that addresses specific localized issue or problem.
            </p>
          </div>
          <div>
            <p>
              We pay utmost attention to the quality of service and handle all issues that we are faced in our work with scientific approach and methodology. We devote lot of our energy and focus to details without losing the sight of the “big picture” or
              the ultimate objective and qualities of the system we are creating.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container max-w-screen-xl pt-24 pb-32 text-2xl">
      <p className="-mt-96 text-center">
        <img src={LevercodeTallinn} alt="Tallinn" className="inline-block" />
      </p>
      <p className="mt-24">
        Since we come from probably <strong>the most innovative e-country in the world</strong>, we are experienced in operating in e-services landscape. Therefore, we have certain expectations on the level of quality of a digital service as well as level
        of data aggregation (data combined from different sources). This forms the ground for specific definitions, explanations and keywords applicable in relevant context. That is natural environment for us but, so far, has been difficult for others to
        comprehend. As a result, we constantly challenge ourselves with a task of tuning ourselves for conveying our unique e-life to other parts of the world.
      </p>
      <p className="mt-16">
        Such approach and methodology has turned us to experts in data patterns discovery, processes analysis and modelling and allows us to offer foundational technologies and services built on low risk, manageable cost and long-term reliability for our
        clients to enjoy.
      </p>
    </div>

    <div className="bg-yellow-800">
      <div className="container max-w-screen-2xl py-32 text-2xl">
        <h2 className="text-6xl font-bold text-gray-800 text-center">Our Pool of Experts</h2>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-16 sm:gap-8 mt-8 sm:mt-24">
          {areasData.map(function (data) {
            return (
              <div key={data.id} className=" bg-white">
                <div className="relative">
                  <div className="absolute inset-x-0 bottom-0 text-white text-center pb-8">
                    <p className="text-3xl">{data.name}</p>
                    <p className="text-xl mt-2">{data.role}</p>
                  </div>

                  <img src={data.img} alt={data.name} />
                </div>
                <p className="text-lg p-8">{data.description}</p>
              </div>
            );
          })}
        </div>
        <div className="container max-w-screen-xl text-2xl text-gray-800">
          <p class="mt-8">
            Based on <strong>Partnership Agreement with Tallinn University of Technology (TalTech)</strong>, we also involve <strong>world-renowned Associated Experts</strong> from Institutes of Computer Science, Software Science, and Health Technologies
            in our research lab and projects.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

const areasData = [
  {
    id: 0,
    name: "Gustav Poola",
    role: "CEO, MSc",
    description:
      "Close to 30 years of diverse experience in the IT technologies, e-platforms, board level integration, large infrastructure development, risk management, and computer science technology markets. He holds international SCRUM Master, Data Protection Officer (DPO), and Money Laundering Risk in Private Banking certificates. Co-author of several patents.",
    img: GustavPoola,
  },
  {
    id: 1,
    name: "Oskar Poola",
    role: "CTO, MSc",
    description:
      "Expert of Analysis of Digital Integrity for Cloud Service Provider platforms. He also excels in Government and commercial e-Governance solutions, technologies, and methods of implementation as well as and digital integrity-oriented application solutions.",
    img: OskarPoola,
  },
  {
    id: 2,
    name: "Tarmo Kõuhkna",
    role: "Head of Levercode Lab & Research, MBA",
    description:
      "Entrepreneurship professional, Kõuhkna is expert in medical device, mobile communication, renewable energy, and environment industries. Currently, he is engaged in the fields of e-governance, digital identity, and healthcare data systems.",
    img: TarmoKouhkna,
  },
  {
    id: 3,
    name: "Kalle Kuuspalu",
    role: "System Process Architect, MSc",
    description: "Seasoned international technology expert and consultant on integrating IT systems and optimizing workflows by different technologies like Electronic Data Interchange and Process Mining.",
    img: KalleKuuspalu,
  },
  {
    id: 4,
    name: "Sergei Zamsharski",
    role: "Chief Product Officer, B.Sc IT",
    description: "Seasoned product manager with strong technical background on developing complete solutions in telecommunication, logistics, banking and security areas.",
    img: SergeiZamsharski,
  },
  {
    id: 5,
    name: "Riho Kruuv",
    role: "Head of Strategic Relations, MA",
    description: "Former diplomat and Estonian Ambassador, Kruuv is expert in global affairs, public policy, Government relations, Digital Transformation, and international business. Avid promoter of e-Governance.",
    img: RihoKruuv,
  },
];

export default AboutPage;
